<template>
  <div class="pos-payment-recognize">
    <PageTitle title="第三方交易支付方式認列" hideBtn />

    <section class="w-full">
      <p class="title">
        瑞乘（銀豹）POS
      </p>
      <div class="container">
        <p style="margin-bottom: 20px">以下支付方式皆能認列於事件行銷、會員等級計算</p>
        <ul class="list-disc flex flex-col" style="padding-left: 30px; gap: 20px">
          <li>預設支付方式：現金支付、儲值卡支付</li>
          <li>自定義支付方式：轉帳、匯款、信用卡、LINE Pay、Apple Pay、Google Pay、Samsung Pay、台灣Pay、街口、一卡通Money、全支付、悠遊付、橘子支付、玉山Wallet、icash Pay、全盈+Pay、Pi拍錢包、ezPay簡單付</li>
          <li>第三方金流支付方式：歐付寶、綠界、藍新、紅陽</li>
        </ul>
      </div>
    </section>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'PosPaymentRecognize',
  setup (props) {
    return {}
  },
})
</script>

<style lang="postcss" scoped>
.title {
    @apply text-primary-100 font-bold text-md leading-[26px] mb-[10px];
}

.container {
    @apply bg-white p-[20px] max-w-full;
    @apply font-medium text-normal leading-[19.2px] text-gray-100;
}
</style>
